import request from '../utils/request'

// 账号管理-已注册页面
export function selectPcAccountInfoByTalent(data) {
    return request({
        url: '/myAccount/selectPcAccountInfoByTalent',
        method: 'post',
        data,
    })
}

// 账号管理-预注册页面
export function advancePcAccountInfo(data) {
    return request({
        url: '/myAccount/advancePcAccountInfo',
        method: 'post',
        data,
    })
}
