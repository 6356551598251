<template>
    <div class="container">
        <BreadcrumbNav :page-name="$t('accountManagement.accountManagement')"></BreadcrumbNav>
        <div class="account_wrap auto">
            <!--   预注册   -->
            <div class="pre_registration" v-if="type">
                <div class="pre_avatar_box">
                    <img alt="" src="../../assets/images/ren_icon.png">
                </div>
                <!--<h2>Richard Pompa</h2>-->
                <!--<p class="pre_hint_one">Chairman & CEO at Goldman Sachs</p>-->
                <div @click="gopage('/register','member')" class="pre_btn auto cur">
                    {{ $t("accountManagement.registerNow") }}
                </div>
                <p class="pre_hint_two">{{ $t("accountManagement.thirdPartySocialAccountLogin") }}:</p>
                <div class="pre_three">
                    <img @click="linkedinLogin()" alt="" class="cur" src="../../assets/images/logoin_sfdl1@2x.png"
                         width="18px"/><!--领英-->
                    <img @click="login('twitter')" alt="" class="cur" src="../../assets/images/logoin_sfdl2@2x.png"
                         width="18px"/><!--Twitter-->
                    <img @click="login('facebook')" alt="" class="cur" src="../../assets/images/logoin_sfdl3@2x.png"
                         width="18px"/><!--Facebook-->
                </div>
            </div>
            <!--   已注册   -->
            <div class="registered" v-else>
                <div class="registered_one">
                    <div class="registered_one_left fl">
                        <img :src="messageBox.talentAvatar" alt="" class="fl">
                        <div class="fl">
                            <h2>{{messageBox.talentName}}</h2>
                            <p>{{messageBox.talentPosition}} at {{messageBox.talentCompany}}</p>
                            <div @click="online" class="out_line cur">{{ $t("accountManagement.logOut") }}</div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="registered_one_right fr">
                        <img alt="" class="fl" src="../../assets/images/icon_3min@2x.png">
                        <div class="fl">
                            <h2>{{$t("personalCenter.buy3Minites")}}：</h2>
                            <p>
                                {{$t("personalCenter.videoCommunication")}}
                                <span>${{messageBox.talentAudio}}</span>
                                {{$t("personalCenter.audioCommunication")}}
                                <span>${{messageBox.talentVideo}}</span>
                            </p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="registered_two">
                    <h2>{{ $t("accountManagement.accountManagement") }}</h2>
                    <div class="registered_two_item">
                        <p>{{ $t("accountManagement.accountNumber") }}</p>
                        <div class="input_box">
                            <input :placeholder="$t('publish.input')" type="text" v-model="account">
                        </div>
                    </div>
                    <div class="registered_two_item">
                        <p>{{ $t("accountManagement.originalPassword") }}</p>
                        <div class="input_box">
                            <input :placeholder="$t('accountManagement.pleaseEnterTheOriginalPassword')" type="password"
                                   v-model="oldPassword">
                        </div>
                    </div>
                    <div class="registered_two_item">
                        <p>{{ $t("accountManagement.newPassword") }}</p>
                        <div class="input_box">
                            <input :placeholder="$t('accountManagement.pleaseEnterANewPassword')" type="password"
                                   v-model="newPassword">
                        </div>
                    </div>
                    <div class="registered_two_item">
                        <p>{{ $t("accountManagement.confirmPassword") }}</p>
                        <div class="input_box">
                            <input :placeholder="$t('accountManagement.pleaseEnterTheNewPasswordAgain')" type="password"
                                   v-model="checkPassword">
                        </div>
                    </div>
                    <div @click="changePsd" class="change_btn cur">{{ $t("accountManagement.changePassword") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import {changePassword, selectPcAccount} from "@/api/userApi";
    import {selectPcAccountInfoByTalent, advancePcAccountInfo} from "@/api/accountManage";
    import {loginByLinkedin, loginByTwitter, loginByFacebook} from "@/api/threeLoginApi.js";
    import hello from "hellojs";

    export default {
        name: "account",
        data() {
            return {
                account: '',
                newPassword: '',
                checkPassword: '',
                oldPassword: '',
                messageBox: {},
            }
        },
        components: {
            BreadcrumbNav,
        },
        created() {
            let account = localStorage.getItem("account");
            if (account) {
                this.isChoose = true
                this.account = account;
            } else {
                this.isChoose = false
            }
            console.log("identify==", this.$store.state.identify)
            this.type = this.$store.state.identify ? 'member' : '';
            //查询会员状态
            this.selectRegisterAccount();
        },

        methods: {
            gopage(url, type) {
                this.$router.push({
                    path: url,
                })
            },
            //退出登录
            online() {
                localStorage.removeItem('token')
                this.token = null
                this.$router.replace('/login')
            },
            //修改密码
            changePsd() {
                let formData = {
                    account: this.account,
                    newPassword: this.newPassword,
                    checkPassword: this.checkPassword,
                    oldPassword: this.oldPassword,
                }
                changePassword(formData).then((res) => {
                    this.$message.success(res.msg)
                    if (res.code == 200) {
                        this.online();
                    }
                })
            },
            //查询已注册页面
            selectRegisterAccount() {
                selectPcAccountInfoByTalent({
                    token: localStorage.getItem('token'
                    )
                }).then((res) => {
                    this.messageBox = res.data;
                    this.account = res.data.account;
                })
            },
            //查询预注册页面
            selectPreRegisterAccount() {
                advancePcAccountInfo({
                    identify: localStorage.getItem('token'
                    )
                }).then((res) => {
                    this.messageBox = res.data;
                })
            },

            //领英登录
            linkedinLogin() {
                var url = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=780zfar2d5gddp' +
                    '&redirect_uri=https://www.blessboss.com/login&scope=r_liteprofile%20r_emailaddress%20w_member_social';
                window.open(url, "_blank");
            },

            //领英回调
            linkedinLoginCode() {
                console.log("lingkeind code = " + this.$route.query.code)
                let linkedinData = {
                    code: this.$route.query.code,
                }
                loginByLinkedin(linkedinData).then((res) => {
                    if (res.code == 200) {
                        this.$store.dispatch("userLogin", res.data)
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.$router.replace('/')
                    } else {
                        this.$message({
                            message: "Error: authorization login failed",
                            type: 'error'
                        });
                    }
                })
            },
            login: function (network) {
                var this_com = this;
                hello(network).login().then((response) => {
                    console.log("=============response=============");
                    console.log(response);
                }, (e) => {
                    console.log(e);
                });
                hello(network).api("me").then(function (json) {
                    console.log("=============memememememe=============");
                    console.log(json)
                    this_com.facebookData = {
                        userId: json._id,
                        userName: json.name,
                        avatar: json.picture,
                        email: json.email,
                    };
                    loginByFacebook(this_com.facebookData).then((res) => {
                        console.log("=============resresresresresresres=============");
                        console.log(res)
                        if (res.code == 200) {
                            this_com.$store.dispatch("userLogin", res.data)
                            this_com.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this_com.$router.replace('/')
                        } else {
                            this.$message({
                                message: "Error: authorization login failed",
                                type: 'error'
                            });
                        }
                    });
                });
            },
        },

        mounted() {
            if (this.$route.query.code) {
                this.linkedinLoginCode();
            }
            hello.init({
                facebook: '1350761151942887',
                twitter: 'mpaIzelt3PHMYcdXsC3NS6Y5O',
            }, {redirect_uri: 'https://www.blessboss.com/login'}, {scope: 'email'});
        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        min-height: 100%;
    }

    .account_wrap {
        width: 1200px;
    }

    .pre_registration {
        min-height: 72vh;
    }

    .pre_avatar_box {
        text-align: center;
        margin-top: 74px;
    }

    .pre_avatar_box img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }

    .pre_registration h2 {
        font-size: 26px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 30px;
        color: #000000;
        text-align: center;
        margin-top: 28px;
    }

    .pre_registration .pre_hint_one {
        font-size: 18px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
        margin-top: 12px;
        text-align: center;
    }

    .pre_btn {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 48px;
        text-align: center;
        margin-top: 59px;
        transition: .3s all;
    }

    .pre_btn:hover {
        background: #000000;
        color: #FFFFFF;
    }

    .pre_registration .pre_hint_two {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
        text-align: center;
        margin-top: 42px;
    }

    .pre_three {
        text-align: center;
        margin-top: 20px;
    }

    .pre_three img {
        margin-right: 17px;
    }

    .pre_three img:nth-child(3) {
        margin-right: 0;
    }

    .registered {

    }

    .registered_one {
        padding: 52px 0;
        border-bottom: 1px solid #E5E5E5;
        box-sizing: border-box;
    }

    .registered_one_left {
        width: 560px;
    }

    .registered_one_left img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-right: 32px;
    }

    .registered_one_left h2 {
        font-size: 26px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 30px;
        color: #000000;
        margin-top: 28px;
    }

    .registered_one_left p {
        font-size: 18px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
        margin-top: 12px;
    }

    .out_line {
        width: 190px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 20px;
        transition: .3s all;
    }

    .out_line:hover {
        background: #000000;
        color: #FFFFFF;
    }

    .registered_one_right {

    }

    .registered_one_right img {
        width: 60px;
        margin-right: 5px;
    }

    .registered_one_right h2 {
        font-size: 18px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
        margin-top: 5px;
        margin-bottom: 6px;
    }

    .registered_one_right p {
        font-size: 18px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
    }

    .registered_one_right span {
        padding-left: 5px;
        padding-right: 20px;
        font-size: 20px;
        font-family: Roboto;
        font-weight: bold;
        line-height: 26px;
        color: #000000;
    }

    .registered_two {
        padding-top: 39px;
    }

    .registered_two > h2 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        margin-bottom: 48px;
    }

    .registered_two_item {
        margin-bottom: 52px;
    }

    .registered_two_item p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        margin-bottom: 22px;
    }

    .input_box {
        width: 695px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        padding: 0 26px;
        box-sizing: border-box;
        line-height: 50px;
    }

    .input_box input {
        background: #FAFAFA;
        width: 600px;
    }

    .change_btn {
        width: 162px;
        height: 50px;
        background: linear-gradient(180deg, #000000 0%, #232321 100%);
        border-radius: 131px;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin: 60px 0 70px 0;
    }
</style>
